import React from 'react';

const ProTableRow = props => {
    const getTime = () => {
        let date = new Date(props.data.txDate);
        let hour = date.getHours();
        let minutes = date.getMinutes();
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        let dd = date.getDate();
        let mm = date.getMonth() + 1; //January is 0!
        let yyyy = date.getFullYear();
        let today = dd + '/' + mm + '/' + yyyy;
        let time = hour + ':' + minutes;
        return {today, time};

    };
    getTime();
    const time = getTime();

    return (
        <tr>
            <td>{props.data.line}</td>
            <td>{props.data.unit}</td>
            <td>{props.data.uuid}</td>
            <td>{props.data.dns}</td>
            <td>{props.data.connectedUser}</td>
            <td>{time.time}</td>
            <td>{time.today}</td>

        </tr>
    );
};

export default ProTableRow;