import React, {Component} from 'react';
import Table from "./Components/Table";
import PopUP from "./PopUP";
import Pagination from "react-js-pagination";
import ClientEditorWindow from "./Components/ClientEditorWindow";
import './CssFolder/Main.css';
import logo from './Images/add.png';
import profile from './Images/DealerProfile.png'
import Graph from './Components/Graph';
import questionMark from './Images/info.png';
import DealersData from './Components/DealersData';
import DealersProfile from "./Components/DealerProfile";
import Dealer from "./Components/Dealer";

const jwtDecode = require('jwt-decode');
export const URL = 'https://gpleadsdb.herokuapp.com';
// export const URL = 'http://localhost:3030';
const token = localStorage.getItem('token');

const adminFromToken = token => token && jwtDecode(token).level < 3;

export const myFetch = (endpoint, token, method, body,) => fetch(URL + '/' + endpoint, {
    headers: {
        'Authorization': token
    },
    method: method || 'GET',
    body
}).then(res => res.json());

class MainApp extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showPopup: false,
            canEnter: token,
            token: token,
            currHandleC: '',
            currPopup: null,
            showClientEditor: false,
            sortValue: -1,
            currEditor: null,
            currHandle: '-1',
            DealerProfile: false,
            currNumOfClients: this.getFromLocalStorage('clientNumber') || '100',
            currTextSearch: '',
            activePage: 1,
            showWrongMessage: false,
            userNameD: '',
            showDealerPage: false,
            password: '',
            admin: token && jwtDecode(token).level < 3,
            userName: this.getFromLocalStorage("userName") || '',
            classStatus: '',
            showDealerData: false,
            showDate: false,
            showAddClient: false,
            validUser: false,
            showDeleteMessage: false,
            deletedClient: [],
            dealersData: [],
            Gdata: [0, 0, 0, 0, 0, 0, 0],
            currSort: undefined,

        };
        if (token)
            this.buildURL(() => this.loadGraph('/total'));

        if (token && jwtDecode(token).level < 3) {
            myFetch('users', token).then(res => {
                this.setState({dealersData: res.data})

            })
        }
    };


    showDeleteClient = (deatils) => () => {
        this.setState({deletedClient: deatils, showDeleteMessage: true});
    };
    removeDeleteClient = () => {
        this.setState({showDeleteMessage: false});

    };
    DealWithAddClientPop = (value) => () => {
        this.setState({showAddClient: value});
    };

    addClientEditor = (val, item) => () => {
        this.setState({showClientEditor: val, currEditor: item});
    };
    putInLocalStorage = (key, value) => {

        localStorage.setItem(key, value);
    };

    getFromLocalStorage = (key) => {
        return localStorage.getItem(key) || undefined
    };

    filterNumOfClients = (handle) => {
        this.setState({currNumOfClients: handle, activePage: 1}, () => {
            this.buildURL();
        });
        this.putInLocalStorage('clientNumber', handle);
    };
    changePages = (handle) => {
        this.setState({activePage: handle}, () => {
            this.buildURL();
        });
    };
    filtersearch = (handle) => {
        this.setState({currTextSearch: handle, activePage: 1}, () => {
            this.buildURL();
        });
    };
    addPasswordF = () => {
        let data = this.state.data.data;
        let endPoint = '/gpwebsite/';
        if (this.state.showDealerData) {
            data = this.state.dealersData;
            endPoint = '/users/';
        }
        const index = data.map(item => item._id).indexOf(this.state.deletedClient._id);
        if (index !== -1) {
            data.splice(index, 1);
            this.setState({});
            this.setState({
                showDeleteMessage: false,
                currNumOfClients: this.state.currNumOfClients - 1
            });
            this.sendToServer(endPoint);

        }

    };
    closeAddDillerPop = () => {
        this.setState({showDealerPage: false})
    };
    sendToServer = (endPoint) => {
        fetch(URL + endPoint + this.state.deletedClient._id, {
            method: "DELETE",
            headers: {
                'Authorization': this.state.token,
                "Content-Type": "application/json"
            }
        }).then(response => response.json())
            .then(() => {
                this.buildURL();
            });
    };
    editDillerOfClient = (userId, id) => {
        fetch(URL + '/gpwebsite/' + id, {
            method: "PATCH",
            headers: {
                'Authorization': this.state.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId,
            })
        }).then(response => response.json())
            .then(data => {
            });

    };
    filterClients = (handle) => {
        this.setState({currHandle: handle, activePage: 1, sortValue: handle}, () => {
            this.buildURL();
        });
    };
    buildURL = onEnd => {
        let url = URL + '/gpwebsite?';
        const {currHandle, currNumOfClients, currTextSearch, activePage, currHandleC} = this.state;
        if ((currHandle > -1 && currHandle < 13) || currHandle === '14') {
            switch (parseInt(currHandle)) {
                case 0:
                    url += "$or[0][handled]=" + currHandle + "&$or[1][classStatus]=noCare";
                    break;
                case 1:
                    url += "classStatus=Care";
                    break;
                case 2:
                    url += "classStatus=waitingForDate";
                    break;
                case 3:
                    url += "classStatus=waitingForClient";
                    break;
                case 4:
                    url += "classStatus=Sale";
                    break;
                case 5:
                    url += "classStatus=unRelevant";
                    break;
                case 6:
                    url += "classStatus=endTreat";
                    break;
                case 7:
                    url += "empty=true";
                    break;
                case 8:
                    url += "classStatus=waitingForMeeting";
                    break;
                case 9:
                    url += "classStatus=coldLead";
                    break;
                case 10:
                    url += "admin=0";
                    break;
                case 11:
                    url += "classStatus=outOfCountry";
                    break;
                case 12:
                    url += "$or[0][classStatus]=waitingForDate&$or[1][classStatus]=waitingForClient";
                    break;
                case 14:
                    url += "classStatus=notAnswer";
                    break;
                default:
                    break;


            }
        }
        if (currHandleC === 13) {
            if (this.state.userNameD !== 'all') {
                url += "&username=" + this.state.userNameD;
                this.loadGraph('/total?username=' + this.state.userNameD);
            } else
                this.loadGraph('/total');

        }

        if (this.state.currSort) {
            url += "&$sort" + this.state.currSort + "=" + this.state.sortTarget;
        }
        if (this.state.sortUpdate) {
            url += "&dateFrom=" + this.state.dateFrom;
        }


        url += "&$limit=" + currNumOfClients;
        if (currTextSearch !== '')
            url += "&text=" + currTextSearch;
        url += "&$skip=" + ((activePage - 1) * (currNumOfClients));
        this.loadURL(url, onEnd);

    };

    loadGraph = (endPoint) => {
        fetch(URL + endPoint, {
            headers: {
                'Authorization': this.state.token,
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(data => {
                let arr = [data.Sale || 0, data.coldLead || 0, data.endTreat || 0, data.waitingForMeeting || 0,
                    (data.waitingForDate || 0) + (data.waitingForClient || 0), data.Care || 0,
                    data.noCare || 0];
                this.setState({Gdata: arr})
            });

    };

    loadURL = (url, onEnd) => {
        fetch(url, {
            headers: {
                'Authorization': this.state.token,
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(url);
                onEnd && onEnd();
                this.setState({data: data, canEnter: true})
            });
        myFetch('users', this.state.token).then(res => {
            this.setState({dealersData: res.data})

        })


    };

    onLogin = () => {
        fetch(URL + '/authentication', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "strategy": "local",
                "username": this.state.userName,
                "password": this.state.password
            })
        }).then(response => response.json())
            .then(data => {
                if (data.accessToken) {

                    localStorage.setItem('token', data.accessToken);
                    localStorage.setItem('userName', this.state.userName);
                    if (jwtDecode(data.accessToken).level < 3) {

                        myFetch('users', data.accessToken).then(res => {
                            this.setState({dealersData: res.data});
                        });
                    }
                    this.setState({
                            token: data.accessToken,
                            canEnter: data.accessToken,
                            admin: adminFromToken(data.accessToken),
                        }, () => this.buildURL(this.loadGraph('/total'))
                    )


                } else {
                    this.setState({showWrongMessage: true})
                }

            })
    };
    logOut = () => {
        localStorage.clear();
        this.setState({
                showPopup: false,
                canEnter: false,
                token: false,
                currHandleC: '',
                currPopup: null,
                showClientEditor: false,
                sortValue: -1,
                currEditor: null,
                currHandle: '-1',
                DealerProfile: false,
                currNumOfClients: '',
                currTextSearch: '',
                activePage: 1,
                showWrongMessage: false,
                userNameD: '',
                showDealerPage: false,
                password: '',
                admin: '',
                userName: '',
                classStatus: '',
                showDealerData: false,
                showDate: false,
                showAddClient: false,
                validUser: false,
                showDeleteMessage: false,
                deletedClient: [],
                dealersData: [],
                Gdata: [0, 0, 0, 0, 0, 0, 0],
                currSort: undefined,


            }
        )
    };
    addDealer = (deatils) => {
        fetch(URL + '/users', {
            method: "POST",
            headers: {
                'Authorization': this.state.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "name": deatils.name,
                "username": deatils.userName,
                "password": deatils.password,
                "email": deatils.email
            })
        }).then(response => {
            response.json().then(() => {
                if (response.ok) {
                    myFetch('users', this.state.token).then(res => {
                        this.setState({
                            dealersData: res.data,
                            validUser: false,
                            showDealerPage: false
                        })

                    })
                } else
                    this.setState({validUser: true})
            });
        })


    };

    clicked = (item) => {

        this.setState({showPopup: true, currPopup: item})
    };
    closePopUp = () => {
        this.setState({showPopup: false, currPopup: null}, this.buildURL)
    };

    onChangeInput = (e) => {
        if (e.target.id === "inputUserName") {
            this.setState({userName: e.target.value.toLowerCase()});
        } else {
            this.setState({password: e.target.value});
        }
    };
    updateDifference = (date) => {
        const today = new Date().getTime();
        const chosen = new Date(date).getTime();
        let difference = parseInt((chosen - today) / 86400000);
        if (difference < 0) {
            difference--;
        }
        return difference;
    };
    onFilterChange = (type) => (e) => {
        if (type === 0) {
            this.filterClients(e.target.value);
        } else if (type === 1) {
            this.filterNumOfClients(e.target.value);
        } else if (type === 2) {
            this.filtersearch(e.target.value);
        } else if (type === 3) {
            this.filtersDiller(13, e.target.value)
        } else if (type === 4) {
            this.sortCol(e.currentTarget.id)
        } else if (type === 5) {
            const date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            const time = date.getTime() - (e.target.value * 24 * 60 * 60 * 1000);
            const val = e.target.value === '0';
            this.setState({
                    dateFrom: val ? 0 : time,
                    sortUpdate: !val,
                    activePage: 1
                }, this.buildURL
            );
        }

    };
    onFilterChangeGraph = (type) => {
        let a = -1;

        switch (type) {
            case 0:
                a = 4;
                break;
            case 1:
                a = 9;
                break;
            case 2:
                a = 6;
                break;
            case 3:
                a = 8;
                break;
            case 4:
                a = 12;
                break;
            case 5:
                a = 1;
                break;
            case 6:
                a = 0;
                break;
            default:
                break;

        }
        this.filterClients(a);

    };
    sortCol = (sortTarget) => {
        let currSortValue = '';
        if (sortTarget === "creationDate")
            currSortValue = this.state.sortTarget !== sortTarget ? 'D' : (this.state.currSort === 'A' ? undefined : 'A');
        else
            currSortValue = this.state.sortTarget !== sortTarget ? 'A' : (this.state.currSort === 'D' ? undefined : 'D');
        this.setState({currSort: currSortValue}, () => {
            this.setState({sortTarget: this.state.currSort ? sortTarget : undefined}, () => {
                this.buildURL()
            })
        })

    };
    filtersDiller = (handle, target) => {
        this.setState({currHandleC: handle, userNameD: target, activePage: 1}, () => {
            this.buildURL();
        })
    };
    changeStatus = (id, currentS, statusClass, showDate) => {
        const index = this.state.data.data.map(item => item._id).indexOf(id);
        if (index !== -1) {
            const deatil = this.state.data.data[index];
            deatil.lastStatus = currentS;
            deatil.classStatus = statusClass;
            deatil.showDate = showDate;
            if (deatil.dateA !== " " && deatil.classStatus !== "waitingForClient" && deatil.classStatus !== "waitingForDate" && deatil.classStatus !== "waitingForMeeting") {
                this.removeDate(id)
            }


            this.setState({});
        }
    };
    removeDate = (id) => {
        fetch(URL + '/gpwebsite/' + id, {
            method: "PATCH",
            headers: {
                'Authorization': this.state.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                dateA: " ",
                chosenDate: " "
            })
        }).then(() => {
        });
    };
    updateDate = (id, date, dateA) => {

        const index = this.state.data.data.map(item => item._id).indexOf(id);
        if (index !== -1) {
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    data: prevState.data.data.map((item, i) => {
                        if (index === i)
                            return {...item, chosenDate: date, dateA};
                        else return item;
                    })
                }
            }));
        }
    };

    addDiller = () => {
        this.setState({showDealerPage: true})
    };
    addToTable = (value, id, date) => {
        value.date = new Date();
        value.date = date;
        value._id = id;
        this.state.data.data.splice(0, 0, value);
        this.setState({});
        this.buildURL();

    };
    addDillerData = () => {
        this.setState({showDealerData: true})
    };
    closeDillerData = () => {
        this.setState({showDealerData: false})
    };
    addDealerProfile = () => {
        this.setState({DealerProfile: !this.state.DealerProfile})
    };

    render() {
        return (
            !this.state.canEnter ? <div className={"normal"}>
                    <h1 id={"titleStart"}>התחברות</h1>
                    <div style={{width: '100%'}}>
                        <div id={"userNameConteiner"}>

                            <p id={"userNameTitle"}> שם משתמש</p>
                            <input type={"text"} id={"inputUserName"} className={"EnterWindow"}
                                   onChange={this.onChangeInput}/>
                        </div>
                        <div id={"passwordContainer"}>
                            <p id={"passwordTitle"} className={"EnterWindow"}> סיסמא</p>

                            <input type={"password"} id={"inputPassword"} className={"EnterWindow"}
                                   onChange={this.onChangeInput}/>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    {this.state.showWrongMessage &&
                    <p style={{width: '100%', textAlign: 'center', color: 'black'}}>שם משתמש או סיסמא לא
                        תקינים או נכונים</p>}
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        marginTop: '50px'
                    }}>
                        <button id={"submitStart"} onClick={this.onLogin}>התחברות</button>
                    </div>
                </div> :
                <div style={{
                    overflowY: 'auto'
                }}>
                    {this.state.data && this.state.canEnter ?
                        <div>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <div className={"iconH"} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'fit-content'

                                }}>
                                    <p id={"clientsTotal"}> מספר
                                        לקוחות:{this.state.data.total}</p>
                                    {!this.state.admin &&
                                    <img alt={''} className={"dealerProfile"} src={profile}
                                         onClick={this.addDealerProfile}/>
                                    }
                                    {this.state.DealerProfile &&
                                    <DealersProfile username={this.state.userName}
                                                    close={this.addDealerProfile}
                                                    userId={this.state.id}
                                                    token={this.state.token}/>}
                                    {this.state.admin &&
                                    <div className={"imageDillers"} style={{zIndex: 999}}>
                                        <img alt={''} src={logo} className={"diller"}
                                             onClick={this.addDiller}/>
                                        <img alt={''} src={questionMark}
                                             className={"dillerMark contain"}
                                             onClick={this.addDillerData}/>
                                    </div>
                                    }

                                </div>
                                <button
                                    style={{
                                        width: 50,
                                        height: 25,
                                        marginTop: 15,
                                        marginLeft: 10,
                                        zIndex: 999
                                    }}
                                    onClick={this.logOut}>התנתק
                                </button>
                            </div>
                            <Table data={this.state.data}
                                   showAdd={this.state.showAddClient}
                                   dealAddPop={this.DealWithAddClientPop}
                                   sales={this.state.Gdata || 0}
                                   clickedfunc={this.clicked}
                                   addToTable={this.addToTable}
                                   updateDifference={this.updateDifference}
                                   onChangeFilter={this.onFilterChange}
                                   defaultValue={this.state.sortValue}
                                   dealersData={this.state.dealersData}
                                   admin={this.state.admin}
                                   showDeleteClient={this.showDeleteClient}
                                   editClient={this.addClientEditor}
                                   token={this.state.token}
                                   value={this.state.currNumOfClients}
                                   imgSortId={this.state.sortTarget}
                                   sortState={this.state.currSort}
                            />
                            {this.state.showDeleteMessage &&
                            <div className={"popUpDiv"} style={{zIndex: 5}}>
                                <div className={"deleteMessage"}>
                                    <p style={{width: '98%', marginRight: '1%'}}> האם אתה בטוח
                                        שברצונך למחוק
                                        את {this.state.deletedClient.firstName || this.state.deletedClient.username} ?</p>
                                    <div style={{
                                        flex: 1
                                    }}/>
                                    <div id={"buttonContainer"}>
                                        <button className={"buttons"} value={"true"}
                                                onClick={this.addPasswordF}>כן
                                        </button>
                                        <div style={{
                                            flex: 1
                                        }}/>
                                        <button className={"buttons"} value={"false"}
                                                onClick={this.removeDeleteClient}>לא
                                        </button>
                                    </div>
                                </div>
                            </div>}
                            <Pagination activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.currNumOfClients}
                                        totalItemsCount={this.state.data.total}
                                        pageRangeDisplayed={5}
                                        onChange={this.changePages}/>
                        </div>
                        : <p>loading</p>
                    }

                    {this.state.showDealerData && this.state.admin &&
                    <DealersData closePage={this.closeDillerData}
                                 showDeleteMessage={this.showDeleteClient}
                                 token={this.state.token}
                                 data={this.state.dealersData}/>
                    }

                    <Graph details={this.state.Gdata} onFilterChangeGraph={this.onFilterChangeGraph}
                           dealerName={this.state.userNameD}/>

                    {this.state.showDealerPage && this.state.admin &&
                    <Dealer closePage={this.closeAddDillerPop} addDealer={this.addDealer}
                            valid={this.state.validUser}/>
                    }
                    {this.state.showPopup &&
                    <PopUP details={this.state.currPopup} isAdmin={this.state.admin}
                           closeMe={this.closePopUp}
                           changeStatus={this.changeStatus} changeDate={this.updateDate}
                           updateDifference={this.updateDifference} token={this.state.token}
                           dealersData={this.state.dealersData}
                           editDillerOfClient={this.editDillerOfClient}

                    />

                    }
                    {this.state.showClientEditor &&
                    <ClientEditorWindow removeEditorClient={this.addClientEditor}
                                        details={this.state.currEditor} token={this.state.token}/>}
                </div>


        )


    }
}

export default MainApp;