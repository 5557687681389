import React, {useState} from 'react';
import {URL} from "../MainApp";

const jwtDecode = require('jwt-decode');
const AddClientWindow =({dealAddPop,addToTable,admin,token,dealersData}) => {
    const adminId = "5dd507e71ac5ec0012513a0d";
    const createDate = () => {
        let today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        let date = new Date();
        let hour = date.getHours();
        let minutes = date.getMinutes();
        today = dd + '/' + mm + '/' + yyyy;

        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        date = hour + ':' + minutes;

        setData({...data, date: today, hour: date});
    };
    const [data, setData] = useState({
        admin: '0',
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        message: '',
        city: '',
        userId: jwtDecode(token).userId,
        date: '',
        hour: '',
        userName: jwtDecode(token).userName
    });
    if (data.date === '')
        createDate();
    const sendToServer = () => {
        const objToSend = {};
        objToSend.creationDate = new Date();
        objToSend.firstName = data.firstName;
        objToSend.lastName = data.lastName;
        objToSend.phoneNumber = data.phone;
        objToSend.city = data.city === '' ? 'אין עיר' : data.city;
        objToSend.email = data.email;
        objToSend.message = data.message;
        objToSend.manual = true;
        setData({...data, admin: data.admin === '1' ? adminId : data.userId});
        objToSend.user = data.admin;
        objToSend.process = [];
        objToSend.handled = "0";
        fetch(URL + '/gpwebsite', {
            method: "POST",
            headers: {
                'Authorization': token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(
                objToSend
            )
        }).then(res => res.json().then(res => {
            addToDiller(res._id).then(res => addToTable(objToSend, res._id, data.date));
        }));
        dealAddPop(false)();
    };

    const addToDiller = (id) => {
        return fetch(URL + '/gpwebsite/' + id, {
            method: "PATCH",
            headers: {
                'Authorization': token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: data.userId
            })
        })
    };


    return (

        <div className={"popUpDiv"}>

            <div id={"clientEditor"}>
                <div>
                    <input type={"button"} className={"exitButton"} value={"x"}
                           id={"buttonExit"} onClick={dealAddPop(false)}/>
                    <table id={"editTable"}>

                        <tbody className={"addTbody"}>
                        <tr>
                            {window.screen.width > 1200 && <th> תאריך</th>}
                            {window.screen.width > 1200 && <th> שעה</th>}
                            <th className={"commentPop"}> שם</th>
                            <th className={"commentPop"}>שם משפחה</th>
                            <th className={"commentPop"}>מס' טלפון</th>
                            <th className={"commentPop"}>עיר</th>
                            <th className={"commentPop"}>אימייל</th>
                            <th className={"commentPop"}>הערת הלקוח</th>
                        </tr>
                        <tr>

                            {window.screen.width > 1200 && <td>{data.date}</td>}
                            {window.screen.width > 1200 && <td>{data.hour}</td>}
                            <td><textarea value={data.firstName}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, firstName: e.target.value})
                            }}/></td>
                            <td><textarea
                                value={data.lastName}
                                className={"textEditorClient"} onChange={(e) => {
                                setData({...data, lastName: e.target.value})
                            }}/></td>
                            <td><textarea value={data.phone}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, phone: e.target.value})
                            }}/></td>
                            <td><textarea value={data.city}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, city: e.target.value})
                            }}/></td>
                            <td><textarea
                                value={data.email}
                                className={"textEditorClient"} onChange={(e) => {
                                setData({...data, email: e.target.value})
                            }}/></td>
                            <td><textarea value={data.message}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, message: e.target.value})
                            }}/></td>
                        </tr>
                        </tbody>
                    </table>

                    <div className={"buttonContain"}>
                        {admin &&
                        <form style={{
                            display: 'flex',
                            width: '35%',
                            height: 'fit-content'
                        }} onChange={(e) => {
                            setData({...data, admin: (e.target.id) === 'admin' ? '1' : '0'})
                        }}>
                            <div className={"AddCon"}>
                                <div className={"addC"}> דילר
                                </div>
                                <input type={"radio"} className={"radioAddClient"}
                                       id={"regular"} name={"add"} defaultChecked={true}/>
                            </div>
                            <div className={"AddCon"}>
                                <div className={"addC"}>אדמין
                                </div>
                                <input type={"radio"} className={"radioAddClient"}
                                       id={"admin"}
                                       name={"add"}/>

                            </div>
                            {data.admin === '0' &&
                            <select className={"selAdd"}
                                    style={{marginRight: 20, alignSelf: 'center'}}
                                    onChange={(e) => {
                                        setData({...data, userId: e.target.value})
                                    }}>{
                                dealersData.map((item, index) => <option key={index}
                                                                               value={item._id}> {dealersData[index].username}</option>)}</select>
                            }
                        </form>}

                        <button className={"buttons"} id={"buttonAdd"}
                                onClick={sendToServer}>שמור
                        </button>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddClientWindow;