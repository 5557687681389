import React, {useState} from 'react';
import Calendar from "react-calendar";

const CalendarPop = ({clickSave}) => {
    const [dates, setDates] = useState({
        date: new Date(),
        chosenDate: new Date(),
        dataA: null,
        difference: 0
    });
    const onChange = (date) => {
        const firstDate = dates.date.getTime();
        const secondDate = date[1].getTime();
        let difference = parseInt((secondDate - firstDate) / 86400000);
        setDates({
            ...dates,
            chosenDate: date,
            difference: difference
        }, clickSave(difference, date));
    };
    return (
        <div className={"popUpDiv"}>
            <div className={"calendarPop"}>
                <div>
                    <p id={"daysC"}> נבחרו : {dates.difference} ימים </p>
                </div>
                <Calendar className={"calendarCl"} returnValue={"range"}
                          onChange={onChange}
                          value={dates.date}/>
            </div>
        </div>
    );
};

export default CalendarPop;