import React from 'react';
import sortDown from "../Images/filterDown.svg";
import sortUp from "../Images/filterUp.svg";
const SortCell = ({onClickSort,sortId,sortState,imgSort,compere,name}) => {
    return (
        <div className={"sortContainer"} onClick={onClickSort(4)}
             id={sortId}>
            {sortState && imgSort === sortId &&
            <img alt={""} src={sortState === compere ? sortDown : sortUp}
                 className={"sortImg contain"}/>
            }
            <span style={{color: 'white'}}>{name}</span>
        </div>
    );
};

export default SortCell;