import React, {Component} from 'react';
import ProTable from './ProTable';
import '../CssFolder/program.css'
import {DateRange} from 'react-date-range';

export const URL = 'https://gpleadsdb.herokuapp.com/programmed';
const NAMES = ["line", "unit", "uuid", "dns", "connectedUser"];

class Program extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            token: localStorage.getItem('token'),
            searchTxt: ["", "", "", "", ""],
            total: 100,
            addRange: false,
            addedRange: false
        };
        this.loadUrl(URL + "?");
    }
    loadUrl = (url) => {

        url += "&$limit=" + this.state.total;
        fetch(url, {
            headers: {
                'Authorization': this.state.token,
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({data: data})
            });
    };
    buildUrl = (e) => {

        let url = URL + "?";
        let arr = this.state.searchTxt;
        if (e.target === null) {
            url += "fromDate=" + this.state.startDate + "&toDate=" + this.state.endDate
        } else if (e !== '-1' && e.currentTarget.id < 10) {
            arr[e.currentTarget.id] = e.target.value;
        } else if (e !== '-1' && e.currentTarget.id !== 11) {
            this.setState({total: e.target.value})
        }
        this.setState({searchTxt: arr}, () => {
            for (let i = 0; i < 5; i++) {
                if (this.state.searchTxt[i] !== "") {
                    url += "&" + NAMES[i] + "=" + this.state.searchTxt[i];
                }
            }
            this.loadUrl(url);
        });
    };
    handleSelect = (e) => {
        let start = new Date(e.startDate._d);
        let end = new Date(e.endDate._d);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 0);
        this.setState({startDate: start.getTime(), endDate: end.getTime()}, () => {
        })
    };

    handleOpen = () => {
        if (this.state.addedRange) {
            this.buildUrl('-1')
        }
        this.setState({addRange: !this.state.addedRange, addedRange: this.state.addRange})
    };

    handleClose = (e) => {
        this.setState({addRange: false, addedRange: true}, () => {
            this.buildUrl(e);
        })
    };

    render() {
        return (
            <div className={'programContainer'}>
                {
                    this.state.data ?
                        <div className={"amount"}>Total: {this.state.data.data.length}</div> :
                        <div className={"load"}>loading</div>
                }
                <div className={"SearchContainer"}>
                    <div className={"title"}>Programmed units</div>
                </div>
                {this.state.addRange && <div className={"dataR"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginBottom: 200
                    }}>
                        <span style={{textAlign: 'center', fontSize: 20}}>Select date range</span>
                        <DateRange style={{backgroundColor: 'rgba(0,0,0,0)', marginTop: 20}}
                                   onChange={this.handleSelect}/>
                        <button id={11}
                                onClick={this.handleClose}>V
                        </button>
                    </div>
                </div>}
                {
                    this.state.data &&
                    <ProTable data={this.state.data.data} buildUrl={this.buildUrl}
                              AddRange={this.handleOpen} addedRange={this.state.addedRange}/>
                }
            </div>
        );
    }
}

export default Program;