import React from 'react';
import SortCell from "./SortCell";


const TableTitleR = ({imgSortId, sortState, onChangeFilter}) => {

    return (
        <tr>
            <th id={"delete"}>מחיקה</th>
            <th>
                <SortCell onClickSort={onChangeFilter} sortId={'creationDate'} sortState={sortState}
                          imgSort={imgSortId} compere={'D'} name={'תאריך'}/>
            </th>
            <th>שעה</th>
            <th>
                <SortCell onClickSort={onChangeFilter} sortId={'firstName'} sortState={sortState}
                          imgSort={imgSortId} compere={'A'} name={'שם'}/>
            </th>
            <th>
                <SortCell onClickSort={onChangeFilter} sortId={'lastName'} sortState={sortState}
                          imgSort={imgSortId} compere={'A'} name={'שם משפחה'}/>
            </th>
            <th> מס' טלפון</th>
            <th>
                <SortCell onClickSort={onChangeFilter} sortId={'city'} sortState={sortState}
                          imgSort={imgSortId} compere={'A'} name={'עיר'}/>
            </th>
            <th>אימייל</th>
            <th>
                <SortCell onClickSort={onChangeFilter} sortId={'message'} sortState={sortState}
                          imgSort={imgSortId} compere={'A'} name={'הערת לקוח'}/>
            </th>
            <th>טיפול אחרון</th>
            <th> טיפול בלקוח</th>
            <th>סטטוס לקוח</th>
            <th>עריכה</th>

        </tr>
    );
};

export default TableTitleR;