import React, { Component } from 'react';
import { URL } from './MainApp';
import CalendarPop from "./Components/CalendarPop.js";
import './CssFolder/PopUp.css';
import TimePicker from 'react-times';

import 'react-times/css/material/default.css';

class PopUP extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            process: this.props.details.process,
            text: "",
            showDeleteMessage: false,
            showComment: true,
            addButtonValue: 'הוספה',
            currentStatus: this.props.details.lastStatus,
            statusClass: this.props.details.classStatus,
            showStatus: false,
            addCalendar: false,
            chosenDate: this.props.details.chosenDate,
            showDate: false,
            addDealer: this.props.details.admin === "0",
            today: new Date(),
            admin: this.props.details.userId === "5dd507e71ac5ec0012513a0d" ? "1" : "0",
            date: this.props.details.dateA,
            difference: 0,
            meetingTime: this.props.details.meetingTime === undefined ? '06:00' : this.props.details.meetingTime
        };
        const status = this.props.details.lastStatus;
        if ((status === "מחכה לתשובה של הלקוח עד" || status === "יש לחזור ללקוח בתאריך" || status === "נקבעה פגישה בתאריך") && this.props.details.dateA !== undefined) {
            this.state.showDate = true;
            this.state.difference = this.props.updateDifference(this.state.date);
        } else if (this.state.statusClass === undefined) {
            if (this.props.details.process.length === 0) {
                this.state.statusClass = 'noCare';
                this.state.currentStatus = "לא טופל";

            } else {
                this.state.statusClass = 'Care';
                this.state.currentStatus = "בטיפול";

            }

        }
    }

    changeStatus = (e) => {
        if (this.props.details.process.length > 0) {
            this.setState({ addDealer: false });
            if (e.target.value === "לא טופל") {
                this.setState({
                    statusClass: 'noCare',
                    currentStatus: e.target.value,
                    showDate: false
                }, this.loadStatus)
            } else if (e.target.value === "בוצעה מכירה") {
                this.setState({
                    statusClass: 'Sale',
                    currentStatus: e.target.value,
                    showDate: false
                }, this.loadStatus)

            } else if (e.target.value === "בטיפול") {
                this.setState({
                    statusClass: 'Care',
                    currentStatus: e.target.value,
                    addCalendar: false
                }, this.loadStatus);

            } else if (e.target.value === "לא ענו") {
                this.setState({
                    statusClass: 'notAnswer',
                    currentStatus: e.target.value,
                    addCalendar: false
                }, this.loadStatus);

            } else if (e.target.value === "מחכה לתשובה של הלקוח עד") {
                this.setState({
                    statusClass: 'waitingForClient',
                    currentStatus: e.target.value,
                    addCalendar: true
                }, this.loadStatus);

            } else if (e.target.value === "יש לחזור ללקוח בתאריך") {
                this.setState({
                    statusClass: 'waitingForDate',
                    currentStatus: e.target.value,
                    addCalendar: true
                }, this.loadStatus);

            } else if (e.target.value === "נקבעה פגישה בתאריך") {
                this.setState({
                    statusClass: 'waitingForMeeting',
                    currentStatus: e.target.value,
                    addCalendar: true,
                }, this.loadStatus);

            } else if (e.target.value === "לא רלוונטי") {
                this.setState({
                    statusClass: 'unRelevant',
                    currentStatus: e.target.value,
                    showDate: false
                }, this.loadStatus)

            } else if (e.target.value === "סיום טיפול") {
                this.setState({
                    statusClass: 'endTreat',
                    currentStatus: e.target.value,
                    showDate: false
                }, this.loadStatus)
            } else if (e.target.value === "הועבר לדילר") {
                this.setState({
                    statusClass: 'noCare',
                    currentStatus: e.target.value,
                    showDate: false,
                    addDealer: true,
                }, this.loadStatus)
            } else if (e.target.value === "הועבר לחול") {
                this.setState({
                    statusClass: 'outOfCountry',
                    currentStatus: e.target.value,
                    showDate: false
                }, this.loadStatus)
            } else if (e.target.value === "ליד קר") {
                this.setState({
                    statusClass: 'coldLead',
                    currentStatus: e.target.value,
                    showDate: false
                }, this.loadStatus)
            }
        }
    };
    loadDate = () => {
        fetch(URL + '/gpwebsite/' + this.props.details._id, {
            method: "PATCH",
            headers: {
                'Authorization': this.props.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                chosenDate: this.state.chosenDate,
                dateA: this.state.date
            })
        });
        this.props.changeDate(this.props.details._id, this.state.chosenDate, this.state.date)
    };
    loadTime = () => {
        fetch(URL + '/gpwebsite/' + this.props.details._id, {
            method: "PATCH",
            headers: {
                'Authorization': this.props.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                meetingTime: this.state.meetingTime
            })
        });
        this.props.changeDate(this.props.details._id, this.state.chosenDate, this.state.date)
    };

    loadStatus = () => {
        fetch(URL + '/gpwebsite/' + this.props.details._id, {
            method: "PATCH",
            headers: {
                'Authorization': this.props.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                lastStatus: this.state.currentStatus,
                classStatus: this.state.statusClass
            })
        });
        this.props.changeStatus(this.props.details._id, this.state.currentStatus, this.state.statusClass, this.state.showDate);

    };
    loadAdminChange = () => {
        let userId = this.state.admin === "1" ? "5dd507e71ac5ec0012513a0d" : this.props.details.userId;
        fetch(URL + '/gpwebsite/' + this.props.details._id, {
            method: "PATCH",
            headers: {
                'Authorization': this.props.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId,

            })
        });

    };
    setDate = (value) => {
        let today;

        if (value === 0) {
            today = new Date();


        } else {
            today = value;
            this.setState({ date: value });

        }
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0!
        let yyyy = today.getFullYear();
        let date = new Date();
        let hour = date.getHours();
        let minutes = date.getMinutes();
        let second = date.getSeconds();
        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }

        today = dd + '/' + mm + '/' + yyyy;

        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (second < 10) {
            second = '0' + second;
        }
        date = hour + ':' + minutes + ':' + second;
        if (value === 0) {
            if (this.state.addButtonValue === 'הוספה') {
                this.state.process.push({
                    date: today,
                    message: this.state.text,
                    hour: date
                })
            } else {
                const pro = this.state.process;
                pro[this.state.editCommentIndex].hour = date;
                pro[this.state.editCommentIndex].date = today;
                this.setState({ process: pro });

            }
        } else {
            this.setState({
                chosenDate: today, addDifference: true

            }, this.loadDate);

        }
    };
    addComment = () => {

        if (this.props.details.process.length < 1) {
            this.setState({ statusClass: 'Care', currentStatus: 'בטיפול' }, this.loadStatus)
        }
        let newProcess;
        if (this.state.addButtonValue === 'הוספה') {
            newProcess = this.state.process;
            this.setDate(0);
            this.setState({ text: " ", showComment: true });

        } else {
            if (this.state.process[this.state.editCommentIndex].message === this.state.text) {
                this.setState({ text: " ", addButtonValue: 'הוספה' });
                return;
            }
            this.setDate(0);
            newProcess = this.state.process.map((item, index) => index === this.state.editCommentIndex ? { ...item, message: this.state.text } : item)
            this.setState({
                text: " ",
                addButtonValue: 'הוספה',
                process: newProcess
            });

        }
        this.sendToServer(newProcess);


    };
    editComment = (index) => () => {

        this.setState({
            showComment: true,
            text: this.state.process[index].message,
            addButtonValue: 'שמור ',
            editCommentIndex: index
        });
    };
    onClickCalendar = (difference, date) => {
        this.setState({
            addCalendar: false,
            difference: difference,
            showDate: true
        }, this.setDate(date[1]));
    };
    deleteComment = (item, index) => () => {
        if (item === 0) {
            this.setState({ showDeleteMessage: true, deleteIndex: index });

        } else if (item === 1) {
            this.setState({ showDeleteMessage: false });


        } else {
            this.state.process.splice(this.state.deleteIndex, 1);
            this.setState({ showDeleteMessage: false }, () => {
                this.sendToServer(this.state.process);

            });
        }
    };

    openCalendar = () => {
        this.setState({
            addCalendar: true
        }, this.loadStatus)
    };

    onTimeChange = (options) => {
        const {
            hour,
            minute,
        } = options;

        this.setState({ meetingTime: (hour + "" + minute) }, this.loadTime);

    };


    sendToServer = (pro) => {
        fetch(URL + '/gpwebsite/' + this.props.details._id, {
            method: "PATCH",
            headers: {
                'Authorization': this.props.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ process: pro, handled: pro.length === 0 ? '0' : '1' })
        });
        if (this.props.details.process.length === 0) {
            this.setState({ statusClass: 'noCare', currentStatus: 'לא טופל' }, this.loadStatus)
        }
    };


    render() {
        const { firstName, lastName } = this.props.details;
        return (
            <div className={ "popUpDiv" }>

                <div className={ "PpoUpChild" }>
                    <div className={ "dialog" }>
                        <button className={ "exitButton" } onClick={ this.props.closeMe }/>
                    </div>

                    { this.state.addCalendar && this.props.details.process.length !== 0 &&
                    <CalendarPop clickSave={ this.onClickCalendar }/> }
                    <div className={ "commentsDiv" }>
                        <p id={ "popUpComment" }> { this.props.details.process.length } הערות </p>

                        { this.props.isAdmin &&
                        <form className={ "formPopUp" } onChange={ (e) => {
                            let admin = e.target.value === 'admin' ? "1" : "0";
                            let addD = e.target.value === 'admin';
                            this.setState({ admin: admin, addDealer: addD }, this.loadAdminChange);
                        } }>
                            <div className={ "AddCon" }>
                                <div className={ "addC" }> דילר
                                </div>
                                <input type={ "radio" } className={ "radioAddClient" }
                                       value={ "regular" } name={ "add" }
                                       defaultChecked={ this.state.admin === "0" }/>
                            </div>
                            <div className={ "AddCon" }>
                                <div className={ "addC" }>אדמין
                                </div>
                                <input type={ "radio" } className={ "radioAddClient" } value={ "admin" }
                                       defaultChecked={ this.state.admin === "1" }
                                       name={ "add" }/>
                            </div>

                        </form> }
                        {
                            this.props.isAdmin && this.state.admin === "0" &&
                            <select name={ "dillers" } className={ "dillersSelect" }
                                    onChange={ (e) => {
                                        this.props.editDillerOfClient(e.target.value, this.props.details._id)
                                    } }
                            >
                                {
                                    this.props.dealersData.map((item, index) => <option
                                        key={ index }
                                        value={ item._id }
                                        selected={ this.props.details.username === item.username }
                                    >{ item.username } </option>) }}

                            </select>
                        }</div>
                    <h1 className={ "popUpName" }>{ firstName } { lastName } </h1>

                    { this.state.showDeleteMessage && <div className={ "popUpDiv" }>
                        <div className={ "deleteMessage" }>

                            <p>האם אתה בטוח שברצונך למחוק את ההערה?</p>
                            <div style={ {
                                flex: 1
                            } }/>
                            <div id={ "buttonContainer" }>
                                <button className={ "buttons" } onClick={ this.deleteComment(2) }>כן
                                </button>
                                <div style={ {
                                    flex: 1
                                } }/>
                                <button className={ "buttons" } onClick={ this.deleteComment(1) }>לא
                                </button>
                            </div>
                        </div>
                    </div> }

                    <div className={ "tablePop" }>
                        <div id={ "statusHolder" }>
                            <div className={ "formPop" }>
                                <p id={ "statusTitle" }>סטטוס </p>

                                <select name="clientStart" id="changeStatus"
                                        className={ this.props.details.process.length === 0 ? 'noCare' : this.state.statusClass }
                                        onChange={ this.changeStatus }
                                        value={ this.props.details.process.length === 0 ? 'לא טופל' : this.state.currentStatus }>

                                    <option value="לא טופל">לא טופל</option>
                                    <option value="בטיפול">בטיפול</option>
                                    <option value="ליד קר">ליד קר</option>
                                    <option value="לא ענו">לא ענו</option>
                                    <option value="בוצעה מכירה">בוצעה מכירה</option>
                                    <option value="יש לחזור ללקוח בתאריך">יש לחזור ללקוח בתאריך
                                    </option>
                                    <option value="מחכה לתשובה של הלקוח עד">מחכים לתשובה של
                                        הלקוח
                                        עד
                                    </option>

                                    <option value="נקבעה פגישה בתאריך">נקבעה פגישה בתאריך
                                    </option>

                                    <option value="סיום טיפול">סיום טיפול
                                    </option>
                                    <option value="לא רלוונטי">לא רלוונטי</option>
                                </select>


                            </div>

                            { this.state.showDate && this.props.details.process.length !== 0 && (this.state.currentStatus === 'יש לחזור ללקוח בתאריך' || this.state.currentStatus === 'מחכה לתשובה של הלקוח עד' || this.state.currentStatus === 'נקבעה פגישה בתאריך') &&
                            <div className={ "dateHolder" }>
                                <p id={ "datec" }
                                   onClick={ this.openCalendar }> { this.state.chosenDate } </p>
                                <p
                                    id={ "datec2" }
                                    className={ this.state.difference === 0 ? 'boldp' : '' }> { this.state.difference > -1 ? "נותרו" : "עברו" } { Math.abs(this.state.difference) } ימים </p>
                            </div> }

                            { this.props.details.lastStatus === "נקבעה פגישה בתאריך"
                            && !this.state.addCalendar && <div id={ "time" }>
                                <TimePicker timeConfig={ {
                                    from: '06:00 AM',
                                    to: '20:00 PM',
                                    step: 15,
                                    unit: 'minutes'
                                } } colorPalette="dark" theme="classic"
                                            time={ this.state.meetingTime }
                                            onTimeChange={ this.onTimeChange.bind(this) }


                                />

                            </div> }


                        </div>

                        <table id={ "TablePopUp" }>
                            <tbody>
                            <tr>
                                <th className={ "dateHourPop" }> תאריך עדכון אחרון</th>
                                <th className={ "dateHourPop" }>שעת עדכון אחרונה</th>
                                <th id={ "commentPop" }>הערות</th>
                                <th id={ "EditPop" }>עריכה</th>
                            </tr>
                            { this.state.process.map((item, index) => <tr key={ index }>
                                <td>
                                    { item.date }
                                </td>
                                <td>
                                    { item.hour }
                                </td>


                                <td>
                                    { item.message }
                                </td>
                                <td>
                                    <input type={ "button" } value={ "X" } id={ "deleteButton" }
                                           onClick={ this.deleteComment(0, index) }/>
                                    <input type={ "button" } value={ "עריכה" } id={ "editButton" }
                                           onClick={ this.editComment(index) }/>
                                </td>
                            </tr>) }
                            </tbody>
                        </table>
                    </div>
                    <div className={ "addContainer" }>
                        { this.state.showComment && <div id={ "commentArea" }>

                        <textarea value={ this.state.text } id={ "addArea" }
                                  onChange={ e => {
                                      this.setState({ text: e.target.value });
                                  } }/></div> }

                        { this.state.showComment &&
                        <input type={ "button" } value={ this.state.addButtonValue }
                               id={ "submitButton" }
                               onClick={ this.addComment }/> }
                    </div>
                </div>

            </div>
        );
    }
}

export default PopUP;