import React from 'react';
import logo from "../Images/phone.jpg";

const TableRow = ({details, clickedfunc, admin, showDate, showDeleteClient, editClient, updateDifference}) => {
    const getTime = () => {
        let date = new Date(details.creationDate);
        let hour = date.getHours();
        let minutes = date.getMinutes();
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        date = hour + ':' + minutes;
        return date;

    };
    const getClass = () => {
        let classVal;
        switch (details.classStatus) {
            case 'noCare':
                classVal = 'noCareR';
                break;
            case 'waitingForDate':
                classVal = 'call';
                break;
            case 'waitingForClient':
                classVal = 'waitingRow';
                break;
            case 'waitingForMeeting':
                classVal = 'meetingRow';
                break;
            case 'Care':
                return;
            case 'coldLead':
                classVal = 'coldLeadRow';
                break;
            case 'dillerClient':
                classVal = admin ? 'dillerClient' : 'noCareR';
                break;
            case 'notAnswer':
                classVal = 'notAnswerRow';
                break;
            default:
                classVal = 'lessRelevantRow';
                break;


        }
        return classVal;
    };
    const className = (details.process.length === 0) ? 'noCareR' : getClass();
    let dealer = admin ? details.userId !== "5dd507e71ac5ec0012513a0d" ? details.username : "admin" : '';
    const diff = updateDifference(details.dateA);

    return (
        <tr className={className}>


            <td id={"delete"}>
                <button id={"deleteButton"}
                        onClick={showDeleteClient(details)}>X
                </button>
            </td>
            <td>{details.manual && <img alt={''} src={logo} className={"phoneIma"}/>}
                {details.date}
            </td>

            <td id={"dateP"}>{getTime()}</td>
            <td>{details.firstName}
            </td>
            <td>{details.lastName}
            </td>
            <td>{details.phoneNumber}
            </td>
            <td>{details.city || 'אין עיר'}
            </td>

            <td>{details.email}
            </td>
            <td>{details.message}
            </td>
            <td>{details.process.length === 0 ? 'לא טופל' : details.process[details.process.length - 1].message}</td>

            <td><input type={"button"} value={'טיפול בלקוח'}
                       className={"buttonClient"}
                       onClick={() => {
                           clickedfunc(details);
                       }}/>
            </td>
            {details.process.length === 0 ?
                <td className={'noCare'}>
                    <div className={"dealerName"}> {dealer}</div>
                    <div style={{color: 'red'}}>לא טופל</div>
                </td> : details.lastStatus === undefined ?
                    <td className={'Care'}>
                        <div className={"dealerName"}> {dealer}</div>
                        <div>בטיפול</div>
                    </td>
                    : <td className={details.classStatus}>
                        <div className={"dealerName"}> {dealer}</div>
                        <div>{details.lastStatus} </div>
                        {showDate && <p>{details.chosenDate}
                            {(details.classStatus === 'waitingForMeeting' && details.meetingTime !== undefined) &&
                            <p>{details.meetingTime.substr(0, 2)
                            }:{details.meetingTime.substr(2, 2)} </p>}
                            <p className={diff === 0 ? 'boldp' : ''}>
                                {
                                    (diff > -1) ? "נותרו" : "עברו"

                                } {Math.abs(updateDifference(details.dateA))} ימים
                            </p>
                        </p>}

                    </td>}


            <td>
                <input type={"button"} value={"עריכה"} id={"editClient"}
                       onClick={editClient(true, details)}/>
            </td>

        </tr>

    );
};

export default TableRow;