import React, {useState} from 'react';

import DealerDataRow from "./DealersDataRow";
import '../CssFolder/Dealer.css'
const DealersData = ({closePage, showDeleteMessage,data,token}) => {
    const [dataDealers, setData] = useState({
        dealerEdit: false,
        dealerData: ''
    });
    const editDealer = (value) => {
        setData({...dataDealers, dealerData: value, dealerEdit: true})
    };
    const saveChanges = () => {
        setData({...dataDealers, dealerEdit: false})
    };
    return (
        <div className={"popUpDiv"}>
            <div className={"PpoUpChild"}>
                <div className={"dialog"}>
                    <button className={"exitButton"} onClick={closePage}/>
                </div>
                <h1 className={"popUpName"}>דילרים:</h1>
                <div className={"dealerPop"} style={{}}>
                    <table className={"dealerP"}>
                        <tbody>

                        <tr className={"tableDiller"}>
                            <td style={{width: '10%'}}>
                                <div> עריכה</div>
                            </td>
                            <td className={"tableCell"}>
                                <div> שם הדילר</div>
                            </td>
                            <td className={"tableCell"}>
                                <div> שם משתמש</div>
                            </td>
                            <td className={"tableCell"}>
                                <div>אימייל</div>
                            </td>
                            <td className={"tableCell"}>
                                <div>סה"כ לקוחות</div>
                            </td>

                        </tr>
                        {data.map((item, index) => <DealerDataRow
                            key={index}
                            save={saveChanges}
                            edit={editDealer}
                            details={item}
                            token={token}
                            showDeleteMessage={showDeleteMessage}/>
                        )
                        }
                        </tbody>

                    </table>

                </div>

            </div>
        </div>
    );
};

export default DealersData;