import React, {useState} from 'react';
import {URL} from "../MainApp";

const ClientEditorWindow = ({removeEditorClient, details, token}) => {
    const [data, setData] = useState(details);
    const sendToServer = () => {
        fetch(URL + '/gpwebsite/' + data._id, {
            method: "PATCH",
            headers: {
                'Authorization': token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                email: data.email,
                message: data.message,
                city: data.city
            })
        }).then(removeEditorClient(false, null)
        );

    };
    return (
        <div className={"popUpDiv"}>
            <div id={"clientEditor"}>
                <div>
                    <input type={"button"} className={"exitButton"} value={"x"}
                           id={"buttonExit"} onClick={removeEditorClient(false, null)}/>
                    <table id={"editTable"}>
                        <tbody className={"addTbody"}>

                        <tr>

                            <th> שם</th>
                            <th>שם משפחה</th>
                            <th>מס' טלפון</th>
                            <th>עיר</th>
                            <th id={"commentPop"}>אימייל</th>
                            <th id={"editPop"}>הודעת הלקוח</th>
                        </tr>
                        <tr>
                            <td><textarea value={data.firstName}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, firstName: e.target.value});
                            }}/></td>
                            <td><textarea value={data.lastName}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, lastName: e.target.value});

                            }}/></td>
                            <td><textarea value={data.phoneNumber}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, phoneNumber: e.target.value});

                            }}/></td>
                            <td><textarea value={data.city || ''}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, city: e.target.value});

                            }}/></td>
                            <td><textarea value={data.email}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, email: e.target.value});

                            }}/></td>
                            <td><textarea value={data.message}
                                          className={"textEditorClient"} onChange={(e) => {
                                setData({...data, message: e.target.value});

                            }}/></td>
                        </tr>
                        </tbody>
                    </table>
                    <button className={"buttons"} id={"buttonAdd"}
                            onClick={sendToServer}>שמור
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ClientEditorWindow;