import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import LocationListener from "./LocationListener";
import MainApp from "./MainApp";
import Program from "./Program/Program"

const token = localStorage.getItem('token');
const jwtDecode = require('jwt-decode');

class App extends Component {

    render() {
        return (
            <div className="App">
                {token && jwtDecode(token).level < 3 ?

                    <BrowserRouter>
                        <LocationListener>
                            <div>
                                <Switch>
                                    <Route exact path={'/'} component={MainApp}/>
                                    <Route exact path={'/program'} component={Program}/>
                                    <Route render={() => <Redirect to="/" replace/>}/>
                                </Switch>

                            </div>
                        </LocationListener>
                    </BrowserRouter> :
                    <MainApp/>
                }

            </div>
        );
    }
}

export default App;
