import React from 'react';
import '../CssFolder/Table.css';
import logo from '../Images/logoGreen.png';
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';
import AddClientWindow from "./AddClientWindow";
import TableTitleR from "./TableTitleR";
import TableRow from "./TableRow";


const Table = props => {
    const allTotal = props.data.allTotal;
    return (
        <div className={"tableCon"}>
            <div className={'tableChild'}>
                {allTotal &&
                <span
                    className={'salesPercent'}>אחוז הצלחה:<span> {Math.round((props.sales[0] / allTotal) * 100)}%</span></span>}
                <span
                    className={'sales'}>סה"כ מכירות שבוצעו:<span> {props.sales[0]} מתוך {allTotal}</span></span>
                <h1 className={"greenTitle"}><span>Green</span> Point <span>Systems</span></h1>
                <img alt={""} src={logo} className={"logoGreen"}/>
            </div>
            <div id={"Container"}>
                <select name="filters" value={props.defaultValue}
                        onChange={props.onChangeFilter(0)}>
                    <option value="-1">כלל הלקוחות</option>
                    <option value="0">לא טופל</option>
                    <option value="14">לא ענו</option>
                    <option value="1">בטיפול</option>
                    <option value="9">לידים קרים</option>
                    <option value="2">בטיפול-עלינו לחזור אל הלקוח</option>
                    <option value="3">בטיפול-מחכים לתשובה של הלקוח</option>
                    <option value="8">נקבעה פגישה</option>
                    <option value="6">סיום טיפול</option>
                    {props.admin && <option value="10">הועבר לדילר</option>}
                    <option value="4">בוצעה מכירה</option>
                    <option value="5">לא רלוונטי</option>
                    <option value="7">חסרים פרטים</option>
                </select>

                <select name="clientStart" id="loadClientsFilter"
                        onChange={props.onChangeFilter(1)} value={props.value}>
                    <option value="10">הצגת 10 לקוחות ראשונים</option>
                    <option value="100">הצגת 100 לקוחות ראשונים</option>
                    <option value="200">הצגת 500 לקוחות ראשונים</option>
                </select>
                {props.admin && Array.isArray(props.dealersData) &&
                <select name="dealers" onChange={props.onChangeFilter(3)}>
                    <option value={'all'}> הכל</option>
                    {props.dealersData.map((item, index) => <option key={index}
                                                                    value={item.username}>
                        {item.username} </option>)}}
                </select>}
                <select name="clientUpdate" id="loadClientsUpdate"
                        onChange={props.onChangeFilter(5)}>
                    <option value="0">הכל</option>
                    <option value="1">יממה אחרונה</option>
                    <option value="7">עודכן בשבוע האחרון</option>
                    <option value="14">עודכן בשבועיים האחרונים</option>
                    <option value="30">עודכן בחודש האחרון</option>
                    <option value="60">עודכן בחודשיים האחרונים</option>
                    <option value="180">עודכן בחצי שנה האחרונה</option>
                </select>
                <div style={{flex: 1}}/>
                <input placeholder={"חיפוש..."} onChange={props.onChangeFilter(2)}
                       id={"search"}/>
            </div>
            <div className={'buttonContainerTable'}
                 id={"addButton"}
                 onClick={props.dealAddPop(true)}>
                <span className={"buttonT"}>+</span>
            </div>

            {props.showAdd &&
            <AddClientWindow dealAddPop={props.dealAddPop}
                             addToTable={props.addToTable}
                             admin={props.admin}
                             token={props.token}
                             dealersData={props.dealersData}/>}
            <table id={"clients"}>
                <tbody>
                <TableTitleR onChangeFilter={props.onChangeFilter} sortState={props.sortState}
                             imgSortId={props.imgSortId}/>
                {Array.isArray(props.data.data) && props.data.data.map((item, index) =>
                    <TableRow key={index}
                              details={item}
                              clickedfunc={props.clickedfunc}
                              admin={props.admin}
                              showDate={item.lastStatus === "מחכה לתשובה של הלקוח עד" || item.lastStatus === "יש לחזור ללקוח בתאריך" || item.lastStatus === "נקבעה פגישה בתאריך"}
                              showDeleteClient={props.showDeleteClient}
                              editClient={props.editClient}
                              updateDifference={props.updateDifference}
                    />)}
                </tbody>
            </table>
        </div>
    );
};

export default Table;