import React, {useState} from 'react';


const Dealer = ({closePage,addDealer,valid}) => {
    const [state, setState] = useState({
        name: '',
        userName: '',
        password: '',
        email: '',
        warningMassage: valid
    });
    const sendToServer = () => {
        addDealer(state);
    };
    return (
        <div className={"popUpDiv"}>
            <div className={"DpoUpChild"}>
                <div className={"dialog"}>
                    <button className={"exitButton"} onClick={closePage}/>
                </div>
                <h1 className={"popUpName"}>הוספת דילר</h1>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    height: '80%'
                }}>
                    <form className={"formDiller"}>
                        <div className={"inputContainer"}>
                            <div className={"textForm"}>
                                שם הדילר
                            </div>
                            <input type={"text"} name={"name"} className={"textDiller"}
                                   onChange={(e) => {
                                       setState({...state, name: e.currentTarget.value})
                                   }}/>
                        </div>
                        <div className={"inputContainer"}>
                            <div className={"textForm"}>
                                שם משתמש
                            </div>
                            <input type={"text"} name={"userName"} className={"textDiller"}
                                   onChange={(e) => {
                                       setState({...state, userName: e.target.value.toLowerCase()})
                                   }}/>
                        </div>
                        <div className={"inputContainer"}>
                            <div className={"textForm"}>
                                אימייל
                            </div>
                            <input type={"text"} name={"email"} className={"textDiller"}
                                   onChange={(e) => {
                                       setState({...state, email: e.target.value})
                                   }}/>
                        </div>
                        <div className={"inputContainer"}>
                            <div className={"textForm"}>
                                סיסמא
                            </div>
                            <input type={"text"} name={"password"} className={"textDiller"}
                                   onChange={(e) => {
                                       setState({...state, password: e.target.value})
                                   }}/>
                        </div>
                    </form>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: '20px'
                    }}>
                        <button className={"buttonDiller"}
                                onClick={sendToServer}>שמור
                        </button>
                    </div>
                    {valid &&
                    <div style={{fontSize: 16, color: 'black', alignSelf: 'center'}}>שם משתמש או
                        סיסמא לא תקינים</div>}

                </div>

            </div>
        </div>
    );
};

export default Dealer;