import React, {Component} from 'react';
import '../CssFolder/DealerProfile.css'
import edit from "../Images/edit.png";
import exit from "../Images/exit.png";
import Loader from 'react-loader-spinner'

export const URL = 'https://gpleadsdb.herokuapp.com/users/';
const jwtDecode = require('jwt-decode');

class DealerProfile extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            token: jwtDecode(this.props.token),
            data: '',
            oldPass: '',
            email: '',
            newPass: '',
            editMail: false,
            editPass: false,
            success: false,
            conPass: '',
            spinner: false,
            wrongText: undefined
        };
        this.loadURL()
    }

    loadURL = () => {
        fetch(URL + this.state.token.userId, {
            headers: {
                'Authorization': this.props.token,
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({data: data, email: data.email || ''})
            });


    };
    changePassword = () => {
        fetch(URL + this.state.token.userId, {
            method: "PATCH",
            headers: {
                'Authorization': this.props.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                oldPassword: this.state.oldPass,
                password: this.state.newPass
            })
        })
            .then(response => response.json())
            .then(data => {
                this.setState({spinner: false});
                if (data.message)
                    this.setState(
                        {wrongText: 'סיסמא קודמת לא תואמת'}
                    );
                else {
                    this.resetState();
                    this.setState({success: true, wrongText: 'סיסמא שונתה בהצלחה'})
                }
            })

    };
    changeEmail = () => {
        fetch(URL + this.state.token.userId, {
            method: "PATCH",
            headers: {
                'Authorization': this.props.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: this.state.email,
            })
        }).then(response => response.json())
            .then(() => {
                this.resetState();
                this.setState({spinner: false, success: true, wrongText: 'כתובת דוא"ל שונה בהצלחה'})

            })


    };
    resetState = () => {
        this.setState({
            token: jwtDecode(this.props.token),
            oldPass: '',
            newPass: '',
            editMail: false,
            editPass: false,
            conPass: '',
            spinner: false,
        })
    };
    savePass = () => {
        if (this.state.newPass !== this.state.conPass) {
            this.setState({wrongText: 'סיסמאות אינן תואמות'});
        } else if (this.state.oldPass === '')
            this.setState({wrongText: 'יש להכניס סיסמא קודמת'});
        else if (this.state.conPass.length < 4)
            this.setState({wrongText: 'יש להכניס סיסמא בת לפחות 4 תווים'});
        else {
            this.setState({wrongText: undefined, spinner: true});
            this.changePassword();
        }
    };
    saveEmail = () => {

        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))
            this.changeEmail();
        else
            this.setState({wrongText: 'כתובת דוא"ל לא חוקית'})
    };

    render() {
        return (
            <div className={'DealerProfileContainer'}>
                <div className={'DealerHolder'}>
                    <input type={'button'} className={'deleteButton'} value={'x'}
                           onClick={this.props.close}/>
                    <span className={'titleProfile'}>{this.state.token.username}</span>

                    <div style={{display: 'flex', width: '90%', justifyContent: 'center'}}>
                        <img alt={''} src={this.state.editMail ? exit : edit} className={'editPro'}
                             onClick={() => {
                                 this.setState({
                                     success: false,
                                     wrongText: undefined,
                                     editMail: !this.state.editMail,
                                     editPass: false
                                 })
                             }}/>
                        {!this.state.editMail ? <span
                                className={'textProfile'}>{!this.state.email ? '' : (this.state.email === '' ? 'אין אימייל' : this.state.email)}</span> :
                            <input type={'text'} className={'inputMail'}
                                   placeholder={'הכנס דואר אלקטרוני...'} onChange={(e) => {
                                this.setState({email: e.target.value})
                            }}
                                   value={this.state.email}/>
                        }
                    </div>

                    <img alt={''} src={this.state.editPass ? exit : edit} className={'editPro'}
                         onClick={() => {
                             this.setState({
                                 wrongText: undefined,
                                 success: false,
                                 editPass: !this.state.editPass,
                                 editMail: false
                             })
                         }}/>
                    <div className={this.state.editPass ? 'passContainer' : 'passInvalid'}>
                        <input type={'password'} id={'pass'} placeholder={'סיסמא ישנה...'}
                               value={this.state.oldPass}
                               disabled={!this.state.editPass}
                               className={'inputProfile'}
                               onChange={(e) => {
                                   this.setState({oldPass: e.target.value})
                               }}/>
                        <input type={'password'} className={'inputProfile'}
                               disabled={!this.state.editPass}
                               value={this.state.newPass}
                               placeholder={'סיסמא חדשה...'} onChange={(e) => {
                            this.setState({newPass: e.target.value})
                        }}/>
                        <input type={'password'} className={'inputProfile'}
                               disabled={!this.state.editPass}
                               value={this.state.conPass}
                               placeholder={'סיסמא חדשה שנית...'} onChange={(e) => {
                            this.setState({conPass: e.target.value})
                        }}/>

                    </div>
                    <span style={{
                        color: this.state.success ? 'green' : 'red',
                        fontSize: '0.8em',
                        textAlign: 'center',
                        opacity: this.state.wrongText ? 1 : 0
                    }}>{this.state.wrongText}</span>
                    <input type={'button'} className={'profileButton'} value={'שמור'}
                           disabled={!this.state.editPass && !this.state.editMail}
                           onClick={this.state.editPass ? this.savePass : this.saveEmail}/>
                    {this.state.spinner &&
                    <Loader type={'Oval'} color={'black'} width={30} height={30}
                            style={{alignSelf: 'center', marginTop: 5}}/>}
                </div>
            </div>
        );
    }
}

export default DealerProfile;