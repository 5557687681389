import React from 'react';
import ProTableRow from "./ProTableRow";

const ProTable = props => {
    return (
        <div style={{overflow: 'scroll'}}>

            <select name="clientStart" id="amountSearch" onChange={props.buildUrl}>
                <option value={10}>10</option>
                <option value={100}>100</option>
                <option value={200}>500</option>
            </select>
            <table className={"tableP"}>
                <tbody>
                <tr>
                    <th style={{backgroundColor: 'white'}}>
                        <input className={"inputP"} placeholder={"search..."}
                               onChange={props.buildUrl}
                               id={0}/>
                        <div className={"searchIP"}><span
                            style={{alignSelf: 'center', color: 'white'}}>Line</span>
                        </div>
                    </th>
                    <th style={{backgroundColor: 'white'}}>
                        <input className={"inputP"} placeholder={"search..."}
                               onChange={props.buildUrl}
                               id={1}/>
                        <div className={"searchIP"}><span
                            style={{alignSelf: 'center', color: 'white'}}>Unit</span>
                        </div>
                    </th>
                    <th style={{backgroundColor: 'white'}}>
                        <input className={"inputP"} placeholder={"search..."}
                               onChange={props.buildUrl}
                               id={2}/>
                        <div className={"searchIP"}><span
                            style={{alignSelf: 'center', color: 'white'}}>Serial number</span>
                        </div>
                    </th>
                    <th style={{backgroundColor: 'white'}}>
                        <input className={"inputP"} placeholder={"search..."}
                               onChange={props.buildUrl}
                               id={3}/>
                        <div className={"searchIP"}><span
                            style={{alignSelf: 'center', color: 'white'}}>DNS</span>
                        </div>
                    </th>
                    <th style={{backgroundColor: 'white'}}>
                        <input className={"inputP"} placeholder={"search..."}
                               onChange={props.buildUrl}
                               id={4}/>
                        <div className={"searchIP"}><span
                            style={{alignSelf: 'center', color: 'white'}}>User</span>
                        </div>
                    </th>
                    <th className={"noSearch"}>
                        <div className={"searchIP"}><span
                            style={{alignSelf: 'center', color: 'white'}}>Programmed hour</span>
                        </div>
                    </th>
                    <th className={"noSearch"}>
                        <input type={"button"} className={props.addedRange ? "added" : "inputP"}
                               onClick={props.AddRange}
                               value={"Add date range"}
                               id={4}/>
                        <div className={"searchIP"}>
                            <span
                                style={{alignSelf: 'center', color: 'white'}}>Programmed date</span>
                        </div>
                    </th>
                </tr>
                {Array.isArray(props.data) && props.data.map((item, index) =>
                    <ProTableRow key={index}
                               data={item}
                    />)}
                </tbody>
            </table>
        </div>
    );
};

export default ProTable;