import React, {useState} from 'react';
import {URL} from "../MainApp";
import saveI from "../Images/save.png";
import editI from "../Images/edit.png";

const jwtDecode = require('jwt-decode');

const DealersDataRow = ({showDeleteMessage,details,token}) => {
    const [data, setData] = useState(details);
    const [state, setState] = useState({
        edit: false,
        token: jwtDecode(token)
    });
    const setDealer = () => {
        fetch(URL +'/users/'+ data._id, {
            method: "PATCH",
            headers: {
                'Authorization': token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: data.email,
                name: data.name,
                username: data.username
            })
        })
            .then(response => response.json())
    };
    const changeEdit = () => {
        if (state.edit)
            setDealer();
        setState({...state, edit: !state.edit})
    };
    return (
        <tr>
            <td>
                <div className={'editContainer'}>
                    <button id={"deleteButton"}
                            onClick={showDeleteMessage(data)}>
                        X
                    </button>
                    <img alt={''} className={'editIcon'} src={state.edit ? saveI : editI}
                         onClick={changeEdit}/>
                </div>
            </td>

            <td>{!state.edit ? data.name || '' :
                <input type={"text"} value={data.name || ''} onChange={(e) => {
                    setData({...data, name: e.target.value})
                }}
                />}</td>
            <td>{!state.edit ? data.username :
                <input type={"text"} value={data.username} onChange={(e) => {
                    setData({...data, username: e.target.value})
                }}/>}</td>
            <td>{!state.edit ? data.email || '' :
                <input type={"text"} value={data.email || ''} onChange={(e) => {
                    setData({...data, email: e.target.value})

                }}/>}</td>
            <td>{data.total}</td>


        </tr>
    );
};

export default DealersDataRow;