import React from 'react';
import '../CssFolder/Graph.css';
import {Bar} from 'react-chartjs-2';

const Graph = ({details, dealerName,onFilterChangeGraph}) => {
    let data = {
        labels: ['בוצעה מכירה','לידים קרים', 'סיום טיפול', 'נקבעה פגישה', 'מחכים ללקוח/ יש לחזור ללקוח', 'בטיפול', 'לא טופל'],
        datasets: [
            {
                label: 'מצב',
                backgroundColor: [
                    '#04a83f',
                    '#1F978D',
                    '#bbbbbb',
                    '#3765c5',
                    '#e5b923',
                    '#e5933c',
                    '#c10000',
                ],
                value: [1, 1, 1, 1, 1, 1,1],


                hoverBackgroundColor: '#D5E0D6',
                width: 700,
                height: 350,
                data: details
            }
        ]
    };
    return (

        <div className={"popUpDivG"}>
            <div className={"GpoUpChild"}>
                <div className={"graphContainer"} style={{}}>
                    <Bar data={data} width={500} height={230} onElementsClick={(elems) => {
                        if (elems[0]) onFilterChangeGraph(elems[0]._index)
                    }} options={{
                        maintainAspectRatio: false
                    }}/>
                </div>
            </div>

        </div>
    );
};

export default Graph;